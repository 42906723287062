import React, { Fragment, useEffect, useRef } from 'react';
import { isMobile } from "react-device-detect";
import { Container, Row, Col, Button } from 'reactstrap';
import usePlaceOrderList from './usePlaceOrderList';
import useGoTo from '../../../../../SharedComponents/GoTo/useGoTo';
import './PlaceOrderList.scss';
import InfiniteScroll from 'react-infinite-scroller';
import searchBoxImage from '../../../../../assets/images/search-box.png';
import useMessages from '../../../../../SharedComponents/Messages/useMessages';
import PlaceOrderListRow from './PlaceOrderListRow';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import itemApi from '../../../../../api/itemApi';
import { useDispatch } from 'react-redux';
import { saveListItems } from '../../../../../reducers/items/itemsActions';
import ShowIf from '../../../../../SharedComponents/ShowIf/ShowIf';

const PlaceOrderList = (props) => {  
  const dispatch = useDispatch();
  const { items, sort, page, loadMore, isLoading, getListItems } = usePlaceOrderList.useApi();
  const { addSpecialRequest } = useGoTo.useActions();
  const { getMessages, getBroadcastMessages } = useMessages.useApiBroadcast();
  
  const isDragging = useRef(false);
  let dragApi;
  const onDragEnd = (result) => {
    isDragging.current = false;
    if (!result.destination || result.destination.index === result.source.index) return;    
    const orderedItemList = reorder(items, result.source.index, result.destination.index);
    dispatch(saveListItems(items));

    const oldIndex = result.source.index;
    const newIndex = result.destination.index;
    const special = newIndex === 0 ? 'top' : (newIndex === items.length-1 ? 'bottom': false );    
    const tag_id= null;
    const itemDistId = orderedItemList[newIndex].itemDistributorId;
    let reference_sort_index = newIndex;
    let sortedItems = [];
    if (newIndex > 0 || newIndex < items.length-1 ){
      reference_sort_index = items[newIndex].sortIndex;
      sortedItems.push(orderedItemList[newIndex-1].itemDistributorId);
      sortedItems.push(orderedItemList[newIndex+1].itemDistributorId);
    }else {  
      reference_sort_index = (newIndex === items.length-1) ? items[oldIndex].sortIndex : items[0].sortIndex;
    }

    const params = {
      item_distributor_ids: sortedItems,
      reference_sort_index: reference_sort_index,
      item_distributor_id: itemDistId,
      tag_id: tag_id,
      special: special
    };

    itemApi.orderListItem(params).then(res => {
      
    }).catch(err => console.error(err));
  };

  const reorder = (itemList, startIndex, endIndex) => {    
    const result = Array.from(itemList);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    const res = result.map((il, i) => (
      {
        ...il,
        sortIndex: i++,
      }
    ));    
    return res;
  };

  const cancelDragSensor = (api) => {
    dragApi = api;
  };

  useEffect(() => {
    getMessages();
    getBroadcastMessages();
  }, []);
 
 
  return (
    <Container fluid={true} className={`place-order-list rounded `+ (isMobile ? `is-mobile overflow-hidden`:``)}>
      <ShowIf show={items.length > 0}>
        <InfiniteScroll
            initialLoad = {false}
            pageStart={page}
            loadMore={() => {if (isDragging.current) { dragApi.tryReleaseLock(); } getListItems(page);}}
            hasMore={ loadMore && !isLoading}
            useWindow={true}>

            <DragDropContext onDragEnd={onDragEnd} sensors={[cancelDragSensor]} onBeforeCapture={() => isDragging.current = true}>
              <Droppable droppableId='itemsList'>
                {(provided, snapshot) => {
                  return (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {
                        items.map((item, i) =>
                          <Draggable key={`listItem_${item.id}`} draggableId={`drag_listItem_${item.id}`} index={i} isDragDisabled={(sort.drag === 0)}>
                            {(provided, snapshot) => {
                              return (
                                <div ref={provided.innerRef} {...provided.draggableProps} id={`draggable_${i}`}>                                      
                                  <PlaceOrderListRow 
                                      showCart={true} 
                                      isDragging={snapshot.isDragging}
                                      dragHandleProps={provided.dragHandleProps} 
                                      index={i}
                                      key={`litem_${item.id}`}
                                  >
                                    {item}
                                  </PlaceOrderListRow>
                                  
                                </div>
                              );
                            }}
                          </Draggable>
                        )
                      }
                      {provided.placeholder}
                    </div>
                  );
                }}
              </Droppable>
            </DragDropContext>
        </InfiniteScroll>
      </ShowIf>

      <ShowIf show={!loadMore && items.length > 0}>
        <Fragment>
          <hr></hr>              
          <Row className='col-12 mt-5 mx-0 px-0'>
            <Col className={`px-0 text-main d-flex align-items-center ` + (isMobile ? `col-12 justify-content-center`:`col-8 justify-content-start`)}>
              Can't find what you are looking for?
            </Col>
            <Col className={`px-0 text-main d-flex align-items-center `+ (isMobile ? `col-12 justify-content-center`:`col-4 justify-content-end`)}>
              <Button onClick={() => addSpecialRequest() } size='lg' color='green' block>Add Special Request</Button>
            </Col>
          </Row>
        </Fragment>
      </ShowIf>

      <ShowIf show={(items.length === 0 && !loadMore && !isLoading && !isMobile)}>
        <Fragment>              
          <Row className='col-12 mt-5 mx-0 px-0'>
            <Col className='col-3 w-fc d-flex align-items-center justify-items-left'>
              <Col className='col wc-img'>
                <img src={searchBoxImage} alt='search box'/>
              </Col>
            </Col>                
            <Col className='col-9 mx-5 px-5 support-text'>
              <Row><p>Sorry, there are no exact match results found</p></Row>
              <Row><p>Search Suggestions</p></Row>
              <Row className='pl-3'>Check your spelling</Row>
              <Row className='pl-3'>Try to use less specific words</Row>
            </Col>                
          </Row>
        </Fragment>
      </ShowIf>      
        
    </Container>    
  );
};

export default PlaceOrderList;